<template>
<!--  <Header :headerName="`Ваш заказ №${order.id}`" />-->
  <div class="container">
    <div class="submiting-goods">
      <div class="order-status">
        <div class="order-status__head">
          <div class="order-status__date">
            {{ order.data }} с {{ order.timeFrom.value }} до {{ order.timeTo.value }}
          </div>
          <div class="order-status__info">{{ order.status }}</div>
        </div>
<!--        <div class="order-status__body">-->
<!--          <button class="order-status__button">Отменить заказ</button>-->
<!--        </div>-->
      </div>
      <div class="submiting-goods__title title title--second">
        Товары&nbsp;
        <span class="submiting-goods__title-span">
          {{ order.products.length }}
        </span>
        <a href="/" class="submiting-goods__title-link">
          <svg class="icon icon-arrow ">
            <use xlink:href="#arrow"></use>
          </svg>
        </a>
      </div>
      <div class="submiting-goods__items" v-if="order.products.length > 0">
        <div class="submiting-goods__item"
             v-for="product in order.products"
             :key="product.id">
          <img :src="product.picture" :alt="product.name" />
        </div>
      </div>
    </div>
    <!-- Payment -->
    <div class="submiting-payment">
      <div class="submiting-payment__title title title--second">Оплата</div>
      <!-- List Products -->
      <div class="submiting-payment__items">
        <div class="submiting-payment__item">
          <div class="submiting-payment__item-text">Продукты</div>
          <div class="submiting-payment__item-value">
            {{ order.payments.productSum }} ₽
          </div>
        </div>
        <div class="submiting-payment__item">
          <div class="submiting-payment__item-text">Доставка</div>
          <div class="submiting-payment__item-value">
            {{ order.payments.priceDelivery }} ₽
          </div>
        </div>
        <!-- Promo code -->
<!--        <template v-if="order.payments.coupons.length > 0">-->
<!--          <div v-for="(item, index) in order.payments.coupons" :key="index" class="submiting-payment__item">-->
<!--            <div class="submiting-payment__item-text">-->
<!--              <span class="submiting-payment__item-text&#45;&#45;promo">{{ item.coupon }}</span>-->
<!--            </div>-->
<!--            <div class="submiting-payment__item-value submiting-payment__item-value&#45;&#45;stock">-{{ item.price }} ₽</div>-->
<!--          </div>-->
<!--        </template>-->
        <!-- Payment method -->
        <div class="submiting-payment__item">
          <div class="submiting-payment__item-text">Способ оплаты</div>
          <div v-if="paySystem">
            <div v-for="(item, index) in paySystem" :key="index" class="submiting-payment__item-value">
              {{ item.name + ' ' + item.sum + ' ' + '₽' }}
              <span v-if="item.isPaid" style="color: #6FCF97; font-weight: bold;">(Оплачено)</span>
              <span v-else style="color: #EB5757; font-weight: bold;">(Ожидается)</span>
            </div>
            <div v-if="order.payments.change" class="submiting-payment__item-span">
              Сдача с {{ order.payments.change }} ₽
            </div>
          </div>
        </div>
      </div>
      <!-- Summary -->
      <div class="submiting-payment__summary" v-if="order.payments.price">
        <div class="submiting-payment__summary-text">Итого:</div>
        <div class="submiting-payment__summary-value">
          {{ order.payments.price }} ₽
        </div>
      </div>
      <div class="submiting-payment__summary" v-if="order.payments.sumPaid">
        <div class="submiting-payment__summary-text">Оплачено:</div>
        <div class="submiting-payment__summary-value">
          {{ order.payments.sumPaid }} ₽
        </div>
      </div>
      <div class="submiting-payment__summary" v-if="order.payments.sumRest">
        <div class="submiting-payment__summary-text">Осталось оплатить:</div>
        <div class="submiting-payment__summary-value">
          {{ order.payments.sumRest }} ₽
        </div>
      </div>
      <!-- Order payment -->
      <div class="submiting-payment__order-payment">
        <div class="submiting-payment__order-payment-item">
          <div class="submiting-payment__order-payment-text">Статус оплаты:</div>
          <div v-if="order.isPaid" class="submiting-payment__order-payment-value paid">Оплачено</div>
          <div v-if="!order.isPaid" class="submiting-payment__order-payment-value unpaid">Ожидается оплата</div>
        </div>
        <template v-if="!order.isPaid">
          <template v-for="(item, index) in paySystem" :key="index">
            <div v-if="item.form"
                 v-html="item.form"
                 class="submiting-payment__order-payment-button"></div>
          </template>
        </template>
      </div>
    </div>
    <!-- Delivery -->
    <div class="submiting-delivery">
      <div class="submiting-delivery__title title title--second">Доставка</div>
      <div class="submiting-delivery__items">
        <div class="submiting-delivery__item" v-if="order.address">
          <div class="submiting-delivery__item-icon">
            <svg class="icon icon-location ">
              <use xlink:href="#location"></use>
            </svg>
          </div>
          <div class="submiting-delivery__item-text">
            {{ order.address.value }}
          </div>
        </div>
        <div v-if="order.timeFrom && order.timeTo"
             class="submiting-delivery__item">
          <div class="submiting-delivery__item-icon">
            <svg class="icon icon-time ">
              <use xlink:href="#time"></use>
            </svg>
          </div>
          <div v-if="order.timeFrom && order.timeTo"
               class="submiting-delivery__item-text">
            {{ order.timeFrom.value + " - " + order.timeTo.value }}
          </div>
        </div>
      </div>
    </div>
    <div class="submiting-status" v-if="order.status === 'Выполнен'">
      <div class="submiting-status__icon">
        <svg class="icon icon-square ">
          <use xlink:href="#square"></use>
        </svg>
      </div>
      <div class="submiting-status__text title title--second">
        Заказ выдан
      </div>
    </div>
    <div class="submiting-action">
      <!--  <button class="btn btn&#45;&#45;grey">Помощь</button>  -->
      <!--  <button class="btn">Повторить заказ</button>  -->
      <router-link to="/review" class="btn btn--grey">Оставить отзыв</router-link>
    </div>
  </div>
</template>

<script>
import store from "@/store/index.js";

export default {
  beforeRouteEnter(to, from, next) {
    store
      .dispatch("PERSONAL_FETCH_ORDER_DETAIL", to.params.orderId)
      .then(() => {
        next();
      });
  },

  computed: {
    order() {
      return this.$store.state.personal.order;
    },

    paySystem() {
      return this.order.payments.paySystem;
    }
  },
};
</script>
